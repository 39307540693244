<template>
    <div class="abilityDetails">

        <!-- 标题 -->
        <!-- <div class="title">我的疗愈计划</div> -->
        <div class="content">
            <!-- 顶部三个按钮 -->
            <!-- <div class="apparatus">
                <div :class="{ gan: currentIndex == index }" v-for="(item, index) in planData" :key="index"
                    @click="changeItem(index)">{{ item.showOrgan }}</div>
            </div> -->
            <!-- 30天肝脏功能修复 -->
            <div class="liver"  v-show="currentIndex == index" v-for="(item, index) in planData" :key="index">
                <img class="img" src="../../../assets/liver.png" alt="">
                <div class="live-top">
                    <div class="live-title"><span>30天</span><span>{{ item.showOrgan }}</span><span>修复</span></div>
                    <div class="live-line"></div>
                </div>
                <div class="live-bottom">
                    <div class="live-bottom-content">
                        <div class="live-bottom-content-photo">
                            <img src="../../../assets/body.png" alt="">
                            <img src="../../../assets/alarm.png" alt="">
                            <img src="../../../assets/yuan.png" alt="">
                            <img src="../../../assets/alarm.png" alt="">
                        </div>
                        <div class="live-bottom-content-every">已有xxxxx人加入</div>
                        <div class="live-bottom-content-join">加入疗愈挑战</div>
                    </div>
                </div>

            </div>

            <!-- 艾健康 -->
            <div class="aiHealthy" v-show="currentIndex == index" v-for="(item, index) in planData" :key="index">
                <div style="display: flex;font-size: 15px;align-items: center;padding: 14px 0;margin-left: 30px;">
                    <div class="aiHealthy-title" style="width: width: 25px;height: 25px;">
                        <img src="../../../assets/alarm.png" alt="" style="width:100%;height:100%;border-radius: 50px;">
                    </div>
                    <span style="margin: 0 20px;">{{ item.content.userName }}</span>
                    <span>{{ item.content.dateNow }}</span>
                </div>
                <div class="live-lineA"></div>
                <div class="aiHealthy-bottom">
                    <div style="display: flex;justify-content: space-between;margin: 14px 0 11px 0;align-items: center;">
                        <div>起始日期</div>
                        <div>{{ item.content.dateSection }}</div>
                    </div>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>总进度</div>
                        <div>
                            <Progress :width="200" :progress="parseFloat(item.content.progress)" :strokeWidth="8"
                                :showInfo="false" />
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;padding: 0 0 16px 0;">
                        <div>当前状态:</div>
                        <div>
                            {{ item.content.organStatus }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="cut-offrule"></div>
            <!-- 自动疗愈 -->
            <!-- <div class="marg_left">
                <slide :type="'health'" :slideItem="slideItem20"></slide>
            </div> -->
            <!-- 疗愈方式模块 -->
            <div class="healing-modular">
                <div class="healing">
                    <div class="healingMedthod">
                        自动疗愈
                    </div>
                    <div class="healingContent">
                        <div class="healing-bottom">
                            <div class="healing-bottom-time-img"><img src="../../../assets/alarm.png" alt=""></div>
                            <div class="healing-bottom-time-content">
                                <div>疗愈时间</div>
                                <div>
                                    <span class="fontSizeA">2</span>
                                    <span class="fontSizeB">小时</span>
                                    <span class="fontSizeA">30</span>
                                    <span class="fontSizeB">分钟</span>
                                </div>
                            </div>
                        </div>
                        <div class="healing-frequency">
                            <div class="healing-frequency-left">
                                <div>累计疗愈次数</div>
                                <div><span class="fontSizeA">20</span><span></span>/次</div>
                            </div>
                            <div class="healing-frequency-center"></div>
                            <div class="healing-frequency-right">
                                <div>累计疗愈时长</div>
                                <div><span class="fontSizeA">50</span><span class="fontSizeB"></span>分</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="healing">
                    <div class="healingMedthod">
                        主动疗愈
                    </div>
                    <div class="healingContent">
                        <div class="healing-bottom">
                            <div class="healing-bottom-time-img"><img src="../../../assets/alarm.png" alt=""></div>
                            <div class="healing-bottom-time-content">
                                <div>疗愈时间</div>
                                <div>
                                    <span class="fontSizeA">2</span>
                                    <span class="fontSizeB">小时</span>
                                    <span class="fontSizeA">30</span>
                                    <span class="fontSizeB">分钟</span>
                                </div>
                            </div>
                        </div>
                        <div class="healing-frequency">
                            <div class="healing-frequency-left">
                                <div>累计疗愈次数</div>
                                <div><span class="fontSizeA">20</span><span></span>/次</div>
                            </div>
                            <div class="healing-frequency-center"></div>
                            <div class="healing-frequency-right">
                                <div>累计疗愈时长</div>
                                <div><span class="fontSizeA">50</span><span class="fontSizeB"></span>分</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cut-offrule"></div>

            <!-- 智能健康管家 -->
            <div class="suggest">
                <div>智能健康管家</div>
            </div>

            <!-- 穴位图片 -->
            <div class="marg_left" v-show="currentIndex == index" v-for="(item, index) in planData" :key="index">
                <!-- 情绪随时间 -->
                <!-- <slide :type="'picture'" :slideItem="slideItem21"></slide>
                <img src="" alt=""> -->
                <!-- <div><img src=" item.content.wearAdvisePicture" alt="" style="width: 100%;height: 100%;"></div> -->
                <div><img src="../../../assets/myHealthPlan/content1.png" alt="" style="width: 100%;height: 100%;"></div>
            </div>

            <!-- 温馨提示 -->
            <div class="Tips">
                <div class="warm">温馨提示</div>
                <ul class="list">
                    <li>
                        1、除建议佩戴位置,如白天有痛点:肩膀痛、膝盖痛、乳房痛、胃疼、头痛等,可根据痛点自行调整佩戴位置
                    </li>
                    <li>2、晚上建议戴重点;男性佩戴前列腺;女性佩戴子宫、卵巢</li>
                    <li>3、智能健康管家离皮肤越近,功效越好,请尽可能接触皮肤佩戴。</li>
                </ul>
            </div>
            <div class="cut-offrule"></div>

            <!-- 生机医疗 -->
            <div class="substance">
                <titlenav :text="'生机食疗'"></titlenav>
            </div>
            <div class="healing-modular" v-show="currentIndex == index" v-for="(item, index) in planData" :key="index">
                <div class="healing">
                    <div class="healingMedthod">
                        食疗汤方
                    </div>
                    <div class="healingContent" style="color:#000;padding: 10px 16px 20px 16px;" v-if="item.content.drinkFormula||item.content.foodFormula">
                      <li>{{ item.content.drinkFormula }}</li>
                      <li>{{ item.content.foodFormula }}</li>
                    </div>
                </div>
                <div class="healing">
                    <div class="healingMedthod">
                        不能吃的食物
                    </div>
                    <div class="healingContent" style="color:#000;padding: 10px 16px 20px 16px;" v-if="item.content.unFoods">
                       {{ item.content.unFoods }}

                    </div>
                </div>
                <div class="healing">
                    <div class="healingMedthod">
                        饮食原则
                    </div>
                    <div class="healingContent" style="color:#000;padding: 10px 16px 20px 16px !important;">
                       <p v-if="item.content.foodPrinciple" v-html="item.content.foodPrinciple"></p>
                    </div>
                </div>
            </div>

            <div class="cut-offrule"></div>

            <div>
                <van-calendar title="每日健康打卡" color="#f77863" :poppable="false" row-height="50" :show-confirm="false"
                    :min-date="minDate" :max-date="maxDate" :style="{ height: '480px' }">

                </van-calendar>
                <div class="button-return">立即打卡</div>
                <div class="get">连续打卡可领取积分</div>

            </div>

            <div class="cut-offrule"></div>
            <!-- 产品推荐 -->
            <div class="substance product">
                <titlenav :text="'产品推荐'"></titlenav>
                <!-- 商品列表 -->
                <goodsList></goodsList>
            </div>
        </div>
    </div>
</template>

<script>
import { GetHealingPlanInfo } from '@/api/index'
import slide from '../../../components/slide'
import data from '@/components/data'
import goodsList from '@/components/goodsList/index'
import { mapGetters } from 'vuex'
import Progress from '../../../components/Progress'
export default {
  name: 'MyHealthPlan',
  components: {
    slide,
    goodsList,
    Progress
  },
  data () {
    return {
      checked: true,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2023, 0, 31),
      slideItem20: data.slideItem20,
      slideItem21: data.slideItem21,
      slideItem22: data.slideItem22,
      planData: '',
      currentIndex: 0,
      value: 50
    }
  },

  methods: {
    GetHealingPlanInfo () {
    //   this.$toast.loading({
    //     message: '获取信息中'
    //   })
      GetHealingPlanInfo({ userId: this.userid }).then(res => {
        const { data } = res
        if (res.code == 0) {
          this.planData = res.data.organDetail
          console.log(this.planData, '+++')
          console.log(this.slideItem22)
        }
      })
    },
    // 选项卡切换
    changeItem (index) {
      this.currentIndex = index
    },
    onChange (value) {
      Toast('当前值：' + value)
    }
  },
  mounted () {
    this.GetHealingPlanInfo()
  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>

<style lang="scss" scoped>
.abilityDetails {
    background: url("../../../assets/healthPlan.png");
    background-size: 100%;
    background-color: #9966FF;
    color: #4f4f4f;
    font-size: 22px;
    position: relative;

    .title {
        text-align: center;
        margin-top: -43px;
    }

    .content {
        width: 100%;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background: #efefef;
        margin-top: 50px;
        color: #808080;

        .line {
            color: #d3d3d3;
            margin-left: 20px;
        }

        .cut-offrule {
            width: 90%;
            border-bottom: 1px solid #ccc;
            margin: 5px auto;
        }

        .apparatus {
            display: flex;
            justify-content: space-evenly;
            padding-top: 20px;

            div {
                width: 100px;
                height: 30px;
                line-height: 30px;
                color: #898989;
                text-align: center;
                font-size: 16px;
                background-color: #f7f8f8;
                border-radius: 20px;
                border: 1px solid #d3d3d3;
            }

            .gan {
                background-color: #ffcec7;
            }
        }

        .challenge {
            // width: 100%;
            height: 137px;

            // background: url('../../../assets/liver.png') no-repeat 100% 100%;
            .img {
                width: 95%;
                margin: 10px auto;
            }

            .thirty {
                margin-top: -140px;
                margin-left: 30px;
            }

            .join {
                width: 95%;
                display: flex;

                .image {
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                }

                .people {
                    margin-top: 30px;
                    margin-left: 5px;
                    font-size: 17px;
                }

                .joins {
                    width: 120px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    margin-left: 5px;
                    color: #e4f9f9;
                    background-color: #00cccc;
                    border-radius: 20px;
                }

            }
        }

        .AiHealth {
            width: 95%;
            height: 150px;
            margin: 0 auto;
            background-color: #ebfffe;
            border-radius: 10px;
            font-size: 18px;
            display: flex;
            flex-direction: column;

            .image {
                width: 30px;
                height: 30px;
                border-radius: 50px;
                margin: 15px 20px;
            }

            .health {
                margin-top: -40px;
                margin-left: 70px;
            }

            .state {
                display: flex;
                justify-content: space-between;
                width: 90%;
                margin-left: 10px;
                font-size: 17px;
                color: #4f4f4f;

                .top {
                    margin-left: 10px;
                    margin-top: 3px;
                }

                .down {
                    text-align: right;
                    font-size: 15px;
                    margin-top: 3px;
                }

                /deep/.van-progress {
                    width: 200px;
                    margin-top: 15px;
                }
            }
        }

        .marg_left {
            display: flex;
        }
    .marg_left div{
        width:100%;

    }
        .suggest {
            font-size: 25px;
            padding: 15px 30px;
            position: relative;
        }

        .suggest::before {
            content: "";
            position: absolute;
            top: 19px;
            left: 16px;
            width: 8px;
            height: 25px;
            border-radius: 5px;
            background-color: #ff9580;
        }

        .Tips {
            margin: 20px 0 0 10px;

            .warm {
                width: 95px;
                border-radius: 10px;
                padding: 5px;
                color: #f7f7f7;
                font-size: 16px;
                text-align: center;
                background: #5dc1b5;
                margin-left: 10px;
            }

            .list {
                width: 330px;
                margin: 10px auto;
                font-size: 14px;
                line-height: 20px;
                color: #808080;
            }
        }

        .substance {
            width: 100%;
            padding: 10px 25px 0 25px;
        }

        .food {
            margin-top: -10px;
        }

        /deep/.van-calendar {
            margin: 20px 10px;
            height: 300px;
            border-radius: 20px;
            color: #626262;
        }

        .button-return {
            background: linear-gradient(to left top, #ff8a69, #ffd5c3);
            width: 50%;
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            font-size: 25px;
            color: white;
            text-align: center;
            margin: -130px auto 80px;
        }

        .get {
            margin: -70px auto 60px;
            font-size: 15px;
            text-align: center;
            color: red;
        }

        .product {
            font-size: 10px;
        }
    }

}

.liver {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding: 0 20px;
    margin: 19px 0 0 0;
}

.live-top {
    position: absolute;
    top: 0;
}

.live-title {
    text-align: left;
    padding-left: 21px;
    margin: 11px 0 5px 0;
    color: #000000 !important;
}

.live-line {
    width: 130%;
    height: 1px;
    border: 1px dashed;
    margin-left: 21px;

}

.live-lineA {
    width: 90%;
    border: 1px dashed;
    margin: 0 auto;

}

.live-bottom {
    width: 90%;
    position: absolute;
    bottom: 14px;
}

.live-bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.live-bottom-content-photo {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    position: relative;

}

.live-bottom-content-photo img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    text-align: center;

}

.live-bottom-content-photo :nth-child(1) {
    position: absolute;
    left: 5px;

}

.live-bottom-content-photo :nth-child(2) {
    position: absolute;
    left: 15px;
}

.live-bottom-content-photo :nth-child(3) {
    position: absolute;
    left: 25px;
}

.live-bottom-content-photo :nth-child(4) {
    position: absolute;
    left: 35px;
}

.live-bottom-content-every {
    font-size: 12px;
    margin-left: 55px;
}

.live-bottom-content-join {
    font-size: 12px;
    background: #00cccc;
    border-radius: 40px;
    text-align: center;
    padding: 10px;
    color: #fff;
}

.aiHealthy {
    width: 90%;
    margin: 19px auto;
    background: #EBFFFE;
    border-radius: 10px;
}

.aiHealthy-bottom {
    font-size: 12px !important;
    color: #000000;
    padding: 0 30px;
}

.custom-button {
    width: 26px;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    background-color: #ee0a24;
    border-radius: 100px;
}

.healing {
    width: 320px;
    height: 240px;
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 20px;
    color: #fff;
    background: #fff;
    margin: 0 10px;
}

.healingMedthod {
    width: 100%;
    background-color: #00CCCC !important;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px 0;
}

.healing-bottom {
    width: 100%;
    color: #808080;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px;
}

.healing-bottom-time-img {
    width: 35px;
    height: 35px;
}

.healing-bottom-time-img img {
    width: 100%;
    height: 100%;
}

.healing-bottom-time-content {
    margin: 0 10px;
}

.healing-frequency {
    color: #808080;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    justify-content: space-between;
    padding: 0 20px 30px 20px;
}

.healing-frequency-center {
    height: 70px;
    width: 1px;
    color: #000;
    border: 1px dotted #000;
}

.fontSizeA {
    font-size: 30px;
    color: #ff9580;
}

.fontSizeB {
    font-size: 18px;

}

.healing-modular {
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: space-between;
    margin: 20px 0;
}
.healingContent{
    height: 200px;
    overflow-y: scroll;
}
</style>
